import { Button } from "@mui/material";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const GiveButton = () => {
    return (
        <Button 
            color="success"
            variant="contained" 
            className="donation-btn"
            startIcon={<VolunteerActivismIcon />}
            target="_blank"
            href="https://meigiving.org/donate/jeffandjenniray"
        >
            Give
        </Button>
    )
}

export default GiveButton;