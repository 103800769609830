import { Grid, Hidden, Typography } from '@mui/material';
import GiveButton from '../../../components/GiveButton'
import ywamLogo from '../../../resources/ywam-logo.svg';
import './Header.css';

function Header() {
    return (
        <header className="app-header">
            <img src="/images/logo.png" className="logo" alt="logo" />
            <Typography variant="h2">
                Angel's Fire
            </Typography>
            <Typography variant="h3">
                Youth With A Mission
            </Typography>
            <Grid 
                container 
                justifyContent='space-around'
                spacing={5}
                style={{ marginTop: '2rem' }} 
            >
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">
                        Jeff and Jenni Ray
                    </Typography>
                    <Typography variant="body1">
                        Directors
                        <br/>
                        <a href="tel:+18155030743" className="link">+1 (815) 503-0743</a>
                    </Typography>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} sm={3}>
                        <GiveButton />
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">
                        Location
                    </Typography>
                    <Typography variant="body1">
                        <a 
                            target="_blank" 
                            rel="noreferrer"
                            className="link"
                            href="https://goo.gl/maps/ZJC5gptPDLTQNezm8" 
                        >
                            505 W Main St.
                            <br/>
                            Tiskilwa, IL 61368
                        </a>
                    </Typography>
                </Grid>
            </Grid>
            <Hidden smUp>
                <GiveButton />
            </Hidden>
            <a 
                target="_blank" 
                rel="noreferrer"
                href="https://ywam.org/location/ywam-angel-s-fire-tiskilwa-il-/b-2342/" 
                className="ywam-logo"
            >
                <img src={ywamLogo} alt="ywam-logo" />
                <span>ywam</span>
            </a>
            <Typography variant="subtitle2">
                Website under development - © Copyright 2022
            </Typography>
        </header>
    );
}

export default Header;
