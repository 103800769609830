import Header from "./views/Temp/Header"

function App() {
    return (
        <div style={{ textAlign: "center" }}>
            <Header />
        </div>
    );
}

export default App;
